import { FC } from "react";
import Button from "src/components/ui/Button/Button";

export interface IProps {
  onAccept: () => void;
}

const CookieBanner: FC<IProps> = props => {
  const { onAccept } = props;

  return (
    <div className="box-shadow-heavy fixed inset-x-0 bottom-0 z-50 flex flex-col items-center justify-center space-y-5 bg-white p-8 text-center">
      <h1 className="text-lg font-bold text-secondary-dark-grey">This Headway Prototype uses cookies</h1>
      <div className="text-base text-secondary-dark-grey">
        <p>
          To take part in testing this prototype you will need to allow cookies. <br />
          Read our privacy policy{" "}
          <a
            href="https://familymentalwealth.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            here
          </a>
          .
        </p>
      </div>
      <Button onClick={onAccept} className="w-[164px] px-0">
        Allow all
      </Button>
    </div>
  );
};

export default CookieBanner;
