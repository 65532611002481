import { Fetcher } from "openapi-typescript-fetch";
import logger from "src/api/middleware/logger";
import { paths } from "src/interfaces/api";
import { getCookie } from "cookies-next";
import { SESSION_COOKIE_KEY } from "src/constants/global";
import { API_BASE_URL } from "src/constants/global";

const fetcher = Fetcher.for<paths>();

export const fetcherConfig = {
  baseUrl: API_BASE_URL,
  init: {
    headers: {
      Authorization: "Bearer " + getCookie(SESSION_COOKIE_KEY)
    }
  },
  use: [logger]
};

fetcher.configure(fetcherConfig);

export default fetcher;
