import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { operations } from "src/interfaces/api";

export interface IFeelingsWheelState {
  childId?: number;
  questionAnswers: {
    [questionId: string]: {
      score: number;
      feeling: string;
      feeling_type: 0 | 1;
    };
  };
  results?: operations["postResults"]["responses"]["200"]["content"]["application/json"];
}

const initialState: IFeelingsWheelState = {
  childId: undefined,
  questionAnswers: {}
};

export const feelingsWheelSlice = createSlice({
  name: "feelingsWheel",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setQuestionAnswer: (
      state,
      action: PayloadAction<{ questionId: string; questionInfo: IFeelingsWheelState["questionAnswers"][string] }>
    ) => {
      state.questionAnswers[action.payload.questionId] = action.payload.questionInfo;
    },
    setFeelingsWheelResults: (state, action: PayloadAction<IFeelingsWheelState["results"]>) => {
      state.results = action.payload;
    }
  }
});

export const { reset, setChildId, setQuestionAnswer, setFeelingsWheelResults } = feelingsWheelSlice.actions;

export default feelingsWheelSlice.reducer;
