import { useRouter } from "next/router";
import { FC } from "react";
import BackBtn from "src/components/ui/Back/BackBtn";
import Button from "src/components/ui/Button/Button";
import Page from "src/layouts/Page";
import ErrorIcon from "public/assets/icons/error.svg";
import Image from "next/image";

export interface IProps {}

const ApiError: FC<IProps> = props => {
  const {} = props;
  const router = useRouter();

  const handleRefresh = () => {
    router.reload();
  };

  return (
    <Page>
      <Page.Header>
        <BackBtn onClick={handleRefresh} />
      </Page.Header>

      <div className="flex flex-1 flex-col items-center justify-center px-7 text-center font-bold text-primary-red">
        <div className="mb-10">
          <Image width={80} height={80} layout="fixed" alt="" role="presentation" src={ErrorIcon} />
        </div>
        <h1 className="text-h1">{`We're sorry, an error occurred`}</h1>
      </div>

      <Page.Footer hasShadow={false}>
        <Button onClick={handleRefresh} btnStyle="primary">
          Refresh
        </Button>
      </Page.Footer>
    </Page>
  );
};

export default ApiError;
