import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface HappyPlaceState {
  childId?: number;
}

const initialState: HappyPlaceState = {
  childId: undefined
};

export const happyPlaceSlice = createSlice({
  name: "happyPlace",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    }
  }
});

export const { reset, setChildId } = happyPlaceSlice.actions;

export default happyPlaceSlice.reducer;
