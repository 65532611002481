import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IOnBoardingState {
  stages: { [slug: string]: boolean };
}

const initialState: IOnBoardingState = {
  stages: {}
};

export const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setStageAsComplete: (state, action: PayloadAction<string>) => {
      state.stages = {
        ...state.stages,
        [action.payload]: true
      };
    }
  }
});

export const { reset, setStageAsComplete } = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
