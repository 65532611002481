import Image from "next/image";
import packageJSON from "package.json";
import HeadwayLogo from "public/headway-logo.png";
import { FC, useEffect } from "react";
import { ESessionUserType, useSessionUserType } from "src/hooks/useSessionData";

interface IProps {
  isShowing: boolean;
  mobileContainerRef?: HTMLDivElement | null;
}

const SplashScreen: FC<IProps> = props => {
  const { isShowing, mobileContainerRef } = props;
  const sessionUserType = useSessionUserType();

  useEffect(() => {
    if (typeof document !== "undefined" && isShowing) {
      document.documentElement.style.setProperty("overflow", "hidden");
      mobileContainerRef?.style.setProperty("overflow", "hidden");
    } else if (typeof document !== "undefined" && !isShowing) {
      document.documentElement.style.removeProperty("overflow");
      mobileContainerRef?.style.removeProperty("overflow");
    }
  }, [isShowing, mobileContainerRef]);

  return isShowing ? (
    <div className="absolute inset-0 z-50 flex flex-col items-center justify-center bg-white text-secondary-purple">
      <Image priority layout="fixed" quality={100} width={216} height={209} alt="Headway Logo" src={HeadwayLogo} />
    </div>
  ) : null;
};

export default SplashScreen;
