import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IFeedbackSliceState {
  childId?: number;
  previousResultsToShow?: "panas" | "caps";
}

const initialState: IFeedbackSliceState = {
  childId: undefined
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    resetActivityDiaryStates: () => {
      return initialState;
    },
    setFeedbackChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setShowPreviousResults: (state, action: PayloadAction<"panas" | "caps" | undefined>) => {
      state.previousResultsToShow = action.payload;
    }
  }
});

export const { resetActivityDiaryStates, setFeedbackChildId, setShowPreviousResults } = feedbackSlice.actions;

export default feedbackSlice.reducer;
