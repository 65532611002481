import classnames from "classnames";
import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, FC, PropsWithChildren, forwardRef } from "react";
import BorderedChevron from "src/components/ui/Chevron/Bordered";
import Chevron from "src/components/ui/Chevron/Chevron";

export interface IProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  hasChevron?: boolean;
  linkProps: LinkProps;
  disabled?: boolean;
  linkStyle?: "primary" | "secondary" | "white";
}

const LinkBtn: FC<PropsWithChildren<IProps>> = props => {
  const { disabled = false, linkProps } = props;

  return disabled ? (
    <LinkEl {...props} />
  ) : (
    <Link {...linkProps} passHref>
      <LinkEl {...props} />
    </Link>
  );
};

const LinkEl = forwardRef<HTMLAnchorElement, PropsWithChildren<IProps>>((props, ref) => {
  const {
    children,
    className,
    linkProps,
    disabled = false,
    hasChevron = false,
    linkStyle = "primary",
    ...rest
  } = props;
  const El = disabled ? "span" : "a";
  const elProps = disabled ? {} : rest;

  return (
    <El
      ref={ref}
      className={classnames(
        className,
        "c-button",
        linkStyle === "primary" && "c-button--primary",
        linkStyle === "secondary" && "c-button--secondary",
        linkStyle === "white" && "c-button--white",
        linkStyle === "primary" && disabled && "bg-secondary-dark-grey",
        linkStyle === "secondary" && disabled && "border-secondary-dark-grey",
        disabled && "cursor-not-allowed"
      )}
      {...elProps}
    >
      {children}
      {hasChevron && linkStyle === "primary" && <Chevron className="c-button-chevron--primary" />}
      {hasChevron && linkStyle === "secondary" && <Chevron colour="purple" className="c-button-chevron--primary" />}
      {hasChevron && linkStyle === "white" && (
        <BorderedChevron className="c-button-chevron--white shadow-[0_1px_12px_rgba(0,0,0,0.12)]" />
      )}
    </El>
  );
});

LinkEl.displayName = "LinkBtn";

export default LinkBtn;
