import { useEffect, useState } from "react";

const useHasDomLoaded = () => {
  const [hasDomLoaded, setHasDomLoaded] = useState(false);

  useEffect(() => setHasDomLoaded(true), []);

  return hasDomLoaded;
};

export default useHasDomLoaded;
