import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { operations } from "src/interfaces/api";

export interface IThoughtSliderState {
  childId?: number;
  questionAnswers: {
    [questionId: string]: {
      score: number;
      quote: string;
    };
  };
  results?: operations["postResults"]["responses"]["200"]["content"]["application/json"];
}

const initialState: IThoughtSliderState = {
  childId: undefined,
  questionAnswers: {}
};

export const thoughtSliderSlice = createSlice({
  name: "thoughtSlider",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setQuestionAnswer: (
      state,
      action: PayloadAction<{ questionId: string; questionInfo: IThoughtSliderState["questionAnswers"][string] }>
    ) => {
      state.questionAnswers[action.payload.questionId] = action.payload.questionInfo;
    },
    setThoughtSliderResults: (state, action: PayloadAction<IThoughtSliderState["results"]>) => {
      state.results = action.payload;
    }
  }
});

export const { reset, setChildId, setQuestionAnswer, setThoughtSliderResults } = thoughtSliderSlice.actions;

export default thoughtSliderSlice.reducer;
