import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ActivityDiarySliceState {
  childId?: number;
  form: ActivityDiaryForm;
  happyPlaceTemplateId?: string;
}

export interface ActivityDiaryForm {
  activity: string;
  completed_at: string;
  feelings: string[];
}

const initialState: ActivityDiarySliceState = {
  childId: undefined,
  form: { activity: "", completed_at: "", feelings: [] },
  happyPlaceTemplateId: undefined
};

export const activityDiarySlice = createSlice({
  name: "activityDiary",
  initialState,
  reducers: {
    resetActivityDiaryStates: () => {
      return initialState;
    },
    setActivityDiaryChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setActivityDiaryForm: (state, action: PayloadAction<Partial<ActivityDiaryForm>>) => {
      state.form = { ...state.form, ...action.payload };
    },
    sethHappyPlaceTemplateId: (state, action: PayloadAction<string | undefined>) => {
      state.happyPlaceTemplateId = action.payload;
    }
  }
});

export const { resetActivityDiaryStates, setActivityDiaryChildId, setActivityDiaryForm, sethHappyPlaceTemplateId } =
  activityDiarySlice.actions;

export default activityDiarySlice.reducer;
