import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ISessionState {
  // Needed for GA events, see src/utils/gtag.ts
  userType: "adult" | "child" | "unknown";
}

const initialState: ISessionState = {
  userType: "unknown"
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionUserType: (state, action: PayloadAction<ISessionState["userType"]>) => {
      state.userType = action.payload;
    }
  }
});

export const { setSessionUserType } = sessionSlice.actions;

export default sessionSlice.reducer;
