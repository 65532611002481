import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ApiSaveResponse {
  data: {
    id: number;
    type: string;
    attributes: HoldThatThoughtAttributes;
  };
  meta: {
    rewards: any[];
  };
}

interface HoldThatThoughtAttributes {
  created_at: string;
  media_type: string;
  media_url: string;
  title: string;
  type: string;
  updated_at: string;
}

export interface HoldThatThoughtState {
  childId?: number;
  video: string | null;
  saveResult: ApiSaveResponse | null;
}

const initialState: HoldThatThoughtState = {
  childId: undefined,
  video: null,
  saveResult: null
};

export const holdThatThoughtSlice = createSlice({
  name: "holdThatThought",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setVideo: (state, action: PayloadAction<string | null>) => {
      state.video = action.payload;
    },
    saveResult: (state, action: PayloadAction<ApiSaveResponse>) => {
      state.saveResult = action.payload;
    }
  }
});

export const { reset, setChildId, setVideo, saveResult } = holdThatThoughtSlice.actions;

export default holdThatThoughtSlice.reducer;
