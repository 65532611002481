import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface JustOneThingState {
  childId?: number;
  form: JustOneThingForm;
  selectedTextToAdd: string;
  parentViewSelectedFilters?: string[];
}

export interface JustOneThingForm {
  feelings: string[];
  best_thing_text: string;
  what_did_you_learn_text: string;
  quote_cursor_position: number;
}

const initialState: JustOneThingState = {
  childId: undefined,
  form: {
    feelings: [],
    best_thing_text: "",
    what_did_you_learn_text: "",
    quote_cursor_position: 0
  },
  selectedTextToAdd: ""
};

export const JustOneThingSlice = createSlice({
  name: "justOneThing",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setChildId: (state, action: PayloadAction<number>) => {
      state.childId = action.payload;
    },
    setForm: (state, action: PayloadAction<Partial<JustOneThingForm>>) => {
      state.form = { ...state.form, ...action.payload };
    },
    setSelectedTextToAdd: (state, action: PayloadAction<string>) => {
      state.selectedTextToAdd = action.payload;
    },
    setParentViewSelectedFilters: (state, action: PayloadAction<string[]>) => {
      state.parentViewSelectedFilters = action.payload;
    }
  }
});

export const { reset, setChildId, setForm, setSelectedTextToAdd, setParentViewSelectedFilters } =
  JustOneThingSlice.actions;

export default JustOneThingSlice.reducer;
