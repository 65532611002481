import { FC, PropsWithChildren, DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import classnames from "classnames";
import Chevron, { ChevronDirection } from "src/components/ui/Chevron/Chevron";
import BorderedChevron from "src/components/ui/Chevron/Bordered";

export interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  hasChevron?: boolean;
  btnStyle?: "primary" | "secondary" | "white" | "link" | "none";
  btnColor?: "secondary-purple" | "primary-purple";
  chevronDirection?: ChevronDirection;
}

const Button: FC<PropsWithChildren<IProps>> = props => {
  const {
    children,
    className,
    btnStyle = "primary",
    btnColor = "primary-purple",
    hasChevron = false,
    chevronDirection,
    ...rest
  } = props;

  return (
    <button
      className={classnames(
        className,
        btnStyle !== "link" && btnStyle !== "none" && "c-button",
        btnStyle === "primary" && "c-button--primary",
        btnStyle === "secondary" && "c-button--secondary",
        btnStyle === "white" && "c-button--white",
        btnStyle === "link" && "c-link",
        btnStyle === "primary" && btnColor === "secondary-purple" && "bg-secondary-purple",
        btnStyle === "secondary" && btnColor === "secondary-purple" && "border-secondary-purple text-secondary-purple"
      )}
      {...rest}
    >
      {children}
      {hasChevron && btnStyle === "primary" && <Chevron className="c-button-chevron--primary" />}
      {hasChevron && btnStyle === "secondary" && <Chevron colour="purple" className="c-button-chevron--primary" />}
      {hasChevron && btnStyle === "white" && (
        <BorderedChevron
          className="c-button-chevron--white shadow-[0_1px_12px_rgba(0,0,0,0.12)]"
          direction={chevronDirection}
        />
      )}
    </button>
  );
};

export default Button;
