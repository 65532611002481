import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import onBoarding, { IOnBoardingState } from "src/redux/modules/on-boarding";
import thoughtSlider, { IThoughtSliderState } from "src/redux/modules/thought-slider";
import holdThatThought, { HoldThatThoughtState } from "src/redux/modules/hold-that-thought";
import feelingsWheel, { IFeelingsWheelState } from "src/redux/modules/feelings-wheel";
import happyPlace, { HappyPlaceState } from "src/redux/modules/happy-place";
import justOneThing, { JustOneThingState } from "src/redux/modules/just-one-thing";
import customBannerUploaderSlice, { ICustomBannerUploaderState } from "src/redux/modules/custom-banner-uploader";
import activityDiary, { ActivityDiarySliceState } from "./modules/activity-diary";
import feedback, { IFeedbackSliceState } from "src/redux/modules/feedback";
import unlockReward, { IUnlockRewardState } from "src/redux/modules/unlock-reward";
import session, { ISessionState } from "src/redux/modules/session";

export type TPreLoadedState = ConfigureStoreOptions<{
  onBoarding: IOnBoardingState;
  thoughtSlider: IThoughtSliderState;
  holdThatThought: HoldThatThoughtState;
  feelingsWheel: IFeelingsWheelState;
  happyPlace: HappyPlaceState;
  justOneThing: JustOneThingState;
  activityDiary: ActivityDiarySliceState;
  feedback: IFeedbackSliceState;
  customBannerUploaderSlice: ICustomBannerUploaderState;
  unlockReward: IUnlockRewardState;
  session: ISessionState;
}>["preloadedState"];

export const createStore = (preloadedState: TPreLoadedState) =>
  configureStore({
    reducer: {
      onBoarding,
      thoughtSlider,
      holdThatThought,
      feelingsWheel,
      happyPlace,
      justOneThing,
      activityDiary,
      feedback,
      customBannerUploaderSlice,
      unlockReward,
      session
    },
    preloadedState
  });

const store = createStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
