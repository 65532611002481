import Image from "next/image";
import ChevronSVGWhite from "public/assets/icons/chevron-right-white.svg";
import ChevronSVGGray from "public/assets/icons/chevron-right-gray.svg";
import ChevronSVGPurple from "public/assets/icons/chevron-right-primary-purple.svg";
import classnames from "classnames";
import { FC, HTMLAttributes } from "react";

interface IProps extends HTMLAttributes<HTMLSpanElement> {
  direction?: ChevronDirection;
  colour?: "white" | "gray" | "purple";
  size?: "normal" | "sm";
}

export type ChevronDirection = "left" | "right" | "down" | "up";

const Chevron: FC<IProps> = props => {
  const { className, colour = "white", direction = "right", size = "normal", ...rest } = props;

  const directionClassNameMapping = {
    right: "",
    left: "rotate-180",
    down: "rotate-90",
    up: "-rotate-90"
  };

  return (
    <span
      className={classnames(
        className,
        "inline-flex transition duration-300 ease-in-out",
        directionClassNameMapping[direction]
      )}
      {...rest}
    >
      <Image
        height={size === "normal" ? 18 : 12}
        width={size === "normal" ? 12 : 8}
        src={colour === "white" ? ChevronSVGWhite : colour === "gray" ? ChevronSVGGray : ChevronSVGPurple}
        role="presentation"
        alt=""
        layout="fixed"
      />
    </span>
  );
};

export default Chevron;
