import { FC } from "react";
import Page from "src/layouts/Page";

export interface IProps {}

const Custom500: FC<IProps> = props => {
  const {} = props;

  return (
    <Page className="flex flex-col items-center justify-center px-8 text-center">
      <h1 className="mb-5 text-h1 font-bold text-primary-red">500</h1>
      <h2 className="mb-5 text-h2 font-bold text-secondary-dark-grey">Internal Server Error</h2>
      <p className="text-base text-secondary-dark-grey">
        The server encountered an error and could not complete your request.
      </p>
    </Page>
  );
};

export default Custom500;
