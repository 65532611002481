import { FC, HTMLAttributes, PropsWithChildren } from "react";
import classnames from "classnames";
import LinkBtn, { IProps as ILinkBtnProps } from "src/components/ui/Link/LinkBtn";
import PaginationDots, { IProps as IPaginationDotsProps } from "src/components/ui/Pagination/Dots";
import Image from "next/image";
import LoadingGif from "public/assets/loading-wheel.gif";

interface IPaginationProps extends HTMLAttributes<HTMLDivElement> {
  paginationProps: IPaginationDotsProps;
}

interface IFooterProps extends HTMLAttributes<HTMLDivElement> {
  hasShadow?: boolean;
  position?: "absolute" | "sticky";
}

interface ILoadingProps {
  title: string;
  className?: string;
}

interface INextBtnProps extends HTMLAttributes<HTMLDivElement> {
  btnProps: ILinkBtnProps;
}

type TPageProps = FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
type TContentProps = FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
type TTitleProps = FC<PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>>;
type TSubTitleProps = FC<PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>>;
type TTextProps = FC<PropsWithChildren<HTMLAttributes<HTMLParagraphElement>>>;
type THeaderProps = FC<HTMLAttributes<HTMLDivElement>>;
type TLoadingProps = FC<ILoadingProps>;
type TFooterProps = FC<PropsWithChildren<IFooterProps>>;
type TPaginationProps = FC<IPaginationProps>;
type TNextBtnProps = FC<PropsWithChildren<INextBtnProps>>;

interface IPageComposition {
  Content: TContentProps;
  Title: TTitleProps;
  SubTitle: TSubTitleProps;
  SecondarySubtitle: TSubTitleProps;
  Text: TTextProps;
  Header: THeaderProps;
  Footer: TFooterProps;
  Pagination: TPaginationProps;
  NextBtn: TNextBtnProps;
  Loading: TLoadingProps;
}

const Page: TPageProps & IPageComposition = props => {
  const { children, className, ...rest } = props;

  return (
    <div className={classnames(className, "flex min-h-screen flex-col")} {...rest}>
      {children}
    </div>
  );
};

const Content: TContentProps = props => {
  const { children, className, ...rest } = props;

  return (
    <div className={classnames(className, "flex flex-1 flex-col items-center px-[34px] pt-[60px]")} {...rest}>
      {children}
    </div>
  );
};

const Title: TTitleProps = props => {
  const { children, className, ...rest } = props;

  return (
    <h1
      className={classnames(className, "mb-5 text-center font-myriad text-h1 font-bold text-secondary-purple")}
      {...rest}
    >
      {children}
    </h1>
  );
};

const SubTitle: TSubTitleProps = props => {
  const { children, className, ...rest } = props;

  return (
    <h2 className={classnames(className, "mb-2 font-myriad text-lg font-bold text-secondary-purple")} {...rest}>
      {children}
    </h2>
  );
};

const SecondarySubtitle: TSubTitleProps = props => {
  const { children, className, ...rest } = props;

  return (
    <h3
      className={classnames(className, "mb-5 w-full font-myriad text-lg font-bold text-secondary-dark-grey")}
      {...rest}
    >
      {children}
    </h3>
  );
};

const Text: TTextProps = props => {
  const { children, className, ...rest } = props;

  return (
    <p className={classnames(className, "mb-5 font-myriad text-secondary-dark-grey")} {...rest}>
      {children}
    </p>
  );
};

const Header: THeaderProps = props => {
  const { children, className, ...rest } = props;

  return (
    <div className={classnames(className, "sticky top-0 z-20 bg-white p-5")} {...rest}>
      {children}
    </div>
  );
};

const Footer: TFooterProps = props => {
  const { children, className, hasShadow = true, position = "sticky", ...rest } = props;

  return (
    <div
      className={classnames(
        className,
        position === "absolute" && "absolute",
        position === "sticky" && "sticky",
        "bottom-0 z-20 mt-auto flex w-full flex-col items-center space-y-4 bg-white p-5",
        hasShadow && "shadow-[0_1px_12px_rgba(0,0,0,0.12)]"
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

const Pagination: TPaginationProps = props => {
  const { className, paginationProps, ...rest } = props;

  return (
    <div className={classnames(className, "-mt-2.5")} {...rest}>
      <PaginationDots {...paginationProps} />
    </div>
  );
};

const NextBtn: TNextBtnProps = props => {
  const { children, className, btnProps, ...rest } = props;

  return (
    <div className={classnames(className, "w-full")} {...rest}>
      <LinkBtn {...btnProps}>{children}</LinkBtn>
    </div>
  );
};

const Loading: TLoadingProps = props => {
  const { title, className } = props;
  return (
    <Page.Content className={className}>
      <div className="relative h-[300px] w-full" aria-label="Loading">
        <Image src={LoadingGif} alt="loading" layout="fill" objectPosition="center" objectFit="contain" />
      </div>
      <Page.Title className="text-center text-primary-purple">{title}</Page.Title>
    </Page.Content>
  );
};

Page.Content = Content;
Page.Title = Title;
Page.SubTitle = SubTitle;
Page.Text = Text;
Page.Pagination = Pagination;
Page.Header = Header;
Page.Footer = Footer;
Page.NextBtn = NextBtn;
Page.SecondarySubtitle = SecondarySubtitle;
Page.Loading = Loading;

export default Page;
