import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ICustomBannerUploaderState {
  customBannerDataBase64: string | null;
}

const initialState: ICustomBannerUploaderState = {
  customBannerDataBase64: null
};

export const customBannerUploaderSlice = createSlice({
  name: "customBannerUploader",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCustomBannerDataBase64: (state, action: PayloadAction<string | null>) => {
      state.customBannerDataBase64 = action.payload;
    }
  }
});

export const { reset, setCustomBannerDataBase64 } = customBannerUploaderSlice.actions;

export default customBannerUploaderSlice.reducer;
