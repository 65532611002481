import { useContext } from "react";
import { SessionContext } from "src/context/SessionProvider";

const useSessionData = () => {
  return useContext(SessionContext);
};

export enum ESessionUserType {
  Unknown = "unknown",
  Adult = "adult",
  Child = "child"
}

export const useSessionUserType = (): ESessionUserType => {
  const sessionData = useSessionData();

  if (sessionData?.data?.attributes?.type === ESessionUserType.Adult) {
    return ESessionUserType.Adult;
  } else if (sessionData?.data?.attributes?.type === ESessionUserType.Child) {
    return ESessionUserType.Child;
  }

  return ESessionUserType.Unknown;
};

export default useSessionData;
