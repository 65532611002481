import "src/styles/globals.css";
import "react-image-crop/src/ReactCrop.scss";
import "src/styles/react-image-crop-overrides.css";
import "@animxyz/core";
import type { AppProps } from "next/app";
import { ApiError } from "openapi-typescript-fetch";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import APIError from "src/layouts/APIError";
import CookieBanner from "src/layouts/CookieBanner";
import Custom500 from "src/pages/500";
import store from "src/redux/store";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import MobileWrapper from "src/layouts/MobileWrapper";
import SessionProvider from "src/context/SessionProvider";
import { hasAcceptedCookies, setAcceptedCookies } from "src/utils/hasAcceptedCookies";
import { ErrorBoundary } from "@sentry/react";

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            useErrorBoundary: true
          }
        }
      })
  );
  const [displayCookieBanner, setDisplayCookieBanner] = useState(false);

  useEffect(() => {
    if (!hasAcceptedCookies()) {
      setDisplayCookieBanner(true);
    }
  }, [setDisplayCookieBanner]);

  const onAcceptCookies = () => {
    setDisplayCookieBanner(false);
    setAcceptedCookies(true);
  };

  return (
    <ErrorBoundary
      fallback={({ error }) => (
        <MobileWrapper hasSplashScreen={false}>
          {error instanceof ApiError ? <APIError /> : <Custom500 />}
        </MobileWrapper>
      )}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <SessionProvider>
              <MobileWrapper>
                <Component {...pageProps} />

                {displayCookieBanner && <CookieBanner onAccept={onAcceptCookies} />}
              </MobileWrapper>
            </SessionProvider>
          </Hydrate>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default MyApp;
