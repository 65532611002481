import { FC, HTMLAttributes } from "react";
import Chevron from "src/components/ui/Chevron/Chevron";
import classnames from "classnames";

export interface IProps extends HTMLAttributes<HTMLButtonElement> {}

const BackLink: FC<IProps> = props => {
  const { className, ...rest } = props;
  if (!rest["aria-label"]) {
    rest["aria-label"] = "Go Back";
  }

  return (
    <button className={classnames(className, "-ml-2 px-2")} {...rest}>
      <Chevron colour="gray" direction="left" />
    </button>
  );
};

export default BackLink;
